import React from "react";
import { navigate } from "gatsby";
import { Container, Button, Row, Col } from "react-bootstrap";
import Layout from "../components/global/Layout/Layout";
import SEO from "../components/global/SEO/SEO";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

const ExpiredLink: React.FC = () => {
  const handleResetPassword = () => {
    navigate("/forgot-password");
  };

  return (
    <Layout>
      <SEO title="Expired Link" />
      <Container className="text-center my-5">
        <Row className="justify-content-center">
          <Col md={6}>
            <img
              src="https://image.e.sonesta.com/lib/fe9c12727364077f75/m/9/78c989cc-cd8e-4d9f-8d4d-f181034301ae.png"
              alt="Expired"
              className="mb-4 img-fluid"
              style={{ maxHeight: "80px" }}
            />
            <h1 className="mb-3">Expired Link</h1>
            <FontAwesomeIcon
              icon={faTimesCircle}
              className="counter-icon"
              size="4x"
              style={{ color: "red" }}
            />
            <p className="mt-4">
              We received a request for your Sonesta Travel Pass account.
            </p>
            <p className="mb-4">For security reasons, this link has expired.</p>
            <Button
              onClick={handleResetPassword}
              variant="dark"
              className="mt-3"
            >
              Reset Password
            </Button>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default ExpiredLink;
